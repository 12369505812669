
const Utils = {

    async sleepSecondsAsync(seconds) {
        return new Promise((resolve) =>setTimeout(resolve, seconds * 1000));
    },

    showDialog(name){
        const obj = {
            "function": false,
            "database": false,
            "sync": false
        }

        obj[name] = true;

        return obj;
    },

    clone(obj){
        return JSON.parse(JSON.stringify(obj));
    },
    
    async sleep(ms){
        return new Promise(resolve => setTimeout(resolve, ms));
    }
           
    
}



export default Utils;