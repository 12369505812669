import { Button } from 'primereact/button';
import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { AiFillDatabase } from "react-icons/ai"

function DatabaseNode({ data }) {
    return (
        <>
            <Handle type="target" position={Position.Left} />
            <Handle type="source" position={Position.Right} />
            <Button disabled={true} className="p-button-warning">
                <div className='diagram-node'>
                    <div className='node-icon'>
                        <AiFillDatabase></AiFillDatabase>
                    </div>
                    <div className='node-name'>
                        {data.label}
                    </div>
                </div>
                
            </Button>
            
            
        </>
    );
}

export default DatabaseNode;