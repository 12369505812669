import {Outlet} from 'react-router-dom';
import TopMenu from './layout/top-menu';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import log from 'loglevel';
import { useContext, useEffect} from 'react';
import UserContext from '../context/user-context';
import { useNavigate } from "react-router-dom";
import UsersFunctions from '../services/functions/users-functions';
import HttpService from '../services/http/http-service';
import Utils from '../services/utils/utils';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import UtilsContext from '../context/utils-context';


export default function MainComponent() {
    
    // App Context
    const {firebaseUser, setUser, setFirebaseUser, user} = useContext(UserContext);
    const {setToast} = useContext(UtilsContext);
    // State
    const toast = useRef(null);
    setToast(toast);

    // Hooks
    useEffect(() => {
        log.debug("[Rendering] Main Component")
        const auth = getAuth();
        onAuthStateChanged(auth, handleAuthStateChanged);
    }, []);

    const navigate = useNavigate();

    // Variables
    // Functions
    const handleAuthStateChanged = async function(firebaseUser){
        log.debug("onAuthStateChanged")
        log.debug("Fire Base User", firebaseUser);
        setFirebaseUser(firebaseUser)
        if(firebaseUser != undefined){

            log.debug("Firebase user is DEFINED")
            HttpService.setJwt(firebaseUser.accessToken);
            var userDataResponse = await UsersFunctions.getUserByFirebaseId(firebaseUser.uid);
            while(userDataResponse.data.id == undefined){
                log.debug("User id is undefined, trying to get again")
                userDataResponse = await UsersFunctions.getUserByFirebaseId(firebaseUser.uid);
                await Utils.sleep(500);
            }
            log.debug("Found user id: " + userDataResponse.data.id)
            setUser(userDataResponse.data);
            
            const desiredPath = sessionStorage.getItem("desiredPath");
            log.debug("Navigating to desired path: " + desiredPath)
            navigate(desiredPath);
        }else{

            log.debug("Not logged in, navigate to login")
            navigate("/login");
        }
    }

    function shouldShowLoadingSpinner(){
        return firebaseUser != undefined && user.id == undefined
    }
    
    // Html

    return (
        <>
            {shouldShowLoadingSpinner() ? <ProgressSpinner className='center-screen'/> : <div>
                <TopMenu></TopMenu>
                <Outlet/>
                <Toast ref={toast} position="bottom-right" />

            </div>
            }
            
        </>
    );
}