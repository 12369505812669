import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';

/**
 * Create a new list of resource and append an item to it
 * @param {*} currentResourceList List of resources
 * @param {*} resource Resources to Add
 * @returns New list of items
 */
function updateResourceList(currentResourceList, resource){
    var updatedResourceList = [...currentResourceList];
    updatedResourceList.push(resource)
    return updatedResourceList
}

const DiagramBuilderService = {

    
    /**
     * 
     * @param {*} currentRessourceList Current Atmos Resource List
     * @param {*} func Function Data
     * @returns Atmos resources, React Flow Nodes and Edges
     */
    addFunction(currentRessourceList, func){
        log.debug("[Builder] Add Function")
        const id = uuidv4()
        func.id = "func-" + id
        func.links = []
        func.position = { x: 0, y: 0 }
        const updatedResourceList = updateResourceList(currentRessourceList, func)
        const d = this.getNodesAndEdgesFromResourceList(updatedResourceList);
        return {
            updatedResourceList: updatedResourceList,
            nodes: d.nodes,
            edges: d.edges
        }
    },

    /**
     * 
     * @param {*} currentRessourceList Current Atmos Resource List
     * @param {*} database Database Data
     * @returns Atmos resources, React Flow Nodes and Edges
     */
    addDatabase(currentRessourceList, database){
        log.debug("[Builder] Add Database")
        const id = uuidv4()
        database.id = "database-" + id
        database.links = []
        database.position = { x: 0, y: 0 }
        const updatedResourceList = updateResourceList(currentRessourceList, database)
        const d = this.getNodesAndEdgesFromResourceList(updatedResourceList);
        return {
            updatedResourceList: updatedResourceList,
            nodes: d.nodes,
            edges: d.edges
        }
    },

    /**
     * 
     * @param {*} currentRessourceList Current Atmos Resource List
     * @param {*} link Link to be added to the resource list. Find the source and target using the name of the resources
     * @returns Atmos resources, React Flow Nodes and Edges
     */
    addLink(currentRessourceList, link){
        var updatedResourceList = [...currentRessourceList];
        const id = uuidv4()
        link.id = "link-" + id
        const source = updatedResourceList.find(x => x.name == link.sourceName)
        const target = updatedResourceList.find(x => x.name == link.targetName)
        link.sourceId = source.id;
        link.targetId = target.id;
        const index = updatedResourceList.findIndex(x => x.name == link.sourceName);
        updatedResourceList[index].links.push(link);
        const d = this.getNodesAndEdgesFromResourceList(updatedResourceList);
        return {
            updatedResourceList: updatedResourceList,
            nodes: d.nodes,
            edges: d.edges
        }
    },

    /**
     * 
     * @param {*} currentRessourceList  Current Atmos Resource List
     * @param {*} sourceId Id of the resource where we want to remove the link from
     * @param {*} linkId Id of the link we want to remove
     * @returns 
     */
    removeLink(currentRessourceList, sourceId, linkId){
        var updatedResourceList = [...currentRessourceList];
        const source = updatedResourceList.find(x => x.id == sourceId);
        source.links = source.links.filter(x => x.id != linkId);
        const d = this.getNodesAndEdgesFromResourceList(updatedResourceList);
        return {
            updatedResourceList: updatedResourceList,
            nodes: d.nodes,
            edges: d.edges
        }
    },

    /**
     * 
     * @param {*} currentRessourceList Current Atmos Resource List
     * @param {*} resource Resource value you want updated (it will search by the id)
     * @returns Atmos resources, React Flow Nodes and Edges
     */
    updateResourceValue(currentRessourceList, resource){
        var updatedResourceList = [...currentRessourceList];
        const index = updatedResourceList.findIndex(x => x.id == resource.id);
        updatedResourceList[index] = resource;
        const d = this.getNodesAndEdgesFromResourceList(updatedResourceList);
        return {
            updatedResourceList: updatedResourceList,
            nodes: d.nodes,
            edges: d.edges
        }
    },

    /**
     * 
     * @param {*} currentResourceList Atmos Resource List
     * @param {*} id Id of the node you want to change the position
     * @param {*} newPosition Position of that node
     * @returns The updated Atmos Resource List
     */
    updateNodePosition(currentResourceList, id, newPosition){
        const index = currentResourceList.findIndex(x => x.id == id);
        var updatedResourceList = [...currentResourceList];
        updatedResourceList[index].position = newPosition;
        return updatedResourceList;
    },

    /**
     * 
     * @param {*} updatedResourceList List of Atmos resources to be transformed
     * @returns React Flow Nodes and Edges
     */
    getNodesAndEdgesFromResourceList(updatedResourceList){
        
        log.debug("[Builder] Generating Nodes and Edges")
        
        let nodes = []
        let edges = []

        const nodeResources = updatedResourceList;

        // Add Nodes
        nodeResources.forEach(resource => {
            var newNode = {}

            // Add Function Nodes
            if(resource.resourceType == "function"){
                newNode = {
                    id: resource.id,
                    data: { label: resource.name },
                    type: "functionNode",
                    position: resource.position
                }
                nodes.push(newNode);
            }

            // Add Database Nodes
            if(resource.resourceType == "database"){
                newNode = {
                    id: resource.id,
                    data: { label: resource.name },
                    type: "databaseNode",
                    position: resource.position
                }
                nodes.push(newNode);
            }
            
        })

        let edgeResources = []
        updatedResourceList.forEach(resource => {
            if(resource.links.length > 0){
                edgeResources = edgeResources.concat(resource.links)
            }
        });
        edgeResources.forEach(resource => {
            var newEdge = {
                id: resource.id,
                source: resource.sourceId,
                target: resource.targetId
            }
            edges.push(newEdge);
            
        })

        return{
            nodes: nodes,
            edges: edges
        }
    },
    
}

export default DiagramBuilderService;