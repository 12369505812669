
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import DiagramContext from '../../../context/diagram-context';
import DiagramBuilderService from '../../../services/diagram/diagram-builder-service';
import DatabaseForm from '../resource-forms/database-form';
import FunctionForm from '../resource-forms/function-form';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import LinksTable from '../tables/links-table';



export default function DiagramRightPanel() {

    // App Context
    const {
        atmosResources, setAtmosResources,
        setDiagramEdges, setDiagramNodes,
        resourceData, setResourceData
    } = useContext(DiagramContext);
    // State
    // Hooks
    useEffect(() => {
        console.log(resourceData);
      
    }, [])
    
    // Variables
    // Functions
    function getForm(){
        if(resourceData?.resourceType == "function"){
            return <>
                {
                    resourceData?.id != "" ? 
                        <div className="p-inputgroup">
                            <span style={{"border": "1px solid black"}} className="p-inputgroup-addon">Id</span>
                            <InputText style={{"border": "1px solid black"}} disabled value={resourceData?.id}></InputText>
                        </div>
                        :
                        ""
                }
                <FunctionForm></FunctionForm>
                <LinksTable></LinksTable>

            </>
        }
        if(resourceData?.resourceType == "database"){
            return <>
                {
                    resourceData?.id != "" ? 
                        <div className="p-inputgroup">
                            <span style={{"border": "1px solid black"}} className="p-inputgroup-addon">Id</span>
                            <InputText style={{"border": "1px solid black"}} disabled value={resourceData?.id}></InputText>
                        </div>
                        :
                        ""
                }
                <DatabaseForm></DatabaseForm>
            </>
        }
        return <></>
    }

    return (
        resourceData?.id != undefined ? 
            <div className='diagram-right-panel'>
                
                {/* Resource Name */}
                <div style={{"fontSize": "1.5em", "margin": "1em", "textAlign": "center"}}>{resourceData?.name}</div>
                <Divider></Divider>

                {/* Resource Form */}
                {getForm()}

                {/* Update Button */}
                <Button style={{"margin": "1em 1em 1em 5em", "width": "70%"}} label="Update" className="p-button-secondary"
                    onClick={() => {
                        const result = DiagramBuilderService.updateResourceValue(atmosResources, resourceData);
                        setAtmosResources(result.updatedResourceList)
                        setDiagramNodes(result.nodes)
                        setDiagramEdges(result.edges)
                    }}
                ></Button>
            </div>
            :
            <></>
        
    );
}