import HttpService from "../http/http-service"
import log from 'loglevel';

const ExportFunctions = {

    async createExport(diagramId, actions, name){
        return await HttpService.post(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/exports", {
            "diagramId": diagramId,
            "actions": actions,
            "name": name
        })
    },

    async getExportById(id){
        return await HttpService.get(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/exports/" + id)
    },

    async getExportByDiagramId(diagramId){
        return await HttpService.get(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/exports/diagram/" + diagramId)
    },


}

export default ExportFunctions;