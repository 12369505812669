import { createContext, useState } from "react";

const UserContext = createContext();

export function UserProvider({children}){

    const [firebaseUser, setFirebaseUser] = useState({});
    const [user, setUser] = useState({});

    return (
        <UserContext.Provider value={
            { 
                firebaseUser, setFirebaseUser,
                user, setUser,
            }
        }>
            {children}</UserContext.Provider>
    )
}


export default UserContext;

