import { useEffect, useRef} from 'react';
import log from 'loglevel';
import FirebaseService from "../services/firebase/firebase-service"
import firebase from 'firebase/compat/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {auth} from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { useContext } from 'react';
import UserContext from '../context/user-context';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {

    // Context
    const {setFirebaseUser} = useContext(UserContext);

    // State
    // Hooks
    useEffect(() => {
        log.debug("[Rendering Page] Login")
        firebaseAuthUI.current = getFirebaseUIObject();
        firebaseAuthUI.current.start('#firebaseui-auth-container', uiConfig);
       
    }, []);

    const navigate = useNavigate();
    
    // Variables
    const firebaseAuthUI = useRef({});
    var uiConfig = {
        // signInSuccessUrl: '/',
        signInOptions: [
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: false
            }
        ],
        tosUrl: '/',
        privacyPolicyUrl: function() {
            window.location.assign('/');
        },
        callbacks:{
            signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                setFirebaseUser(authResult.user);
                navigate("/");
                return false;
            }
        }
    };

    // Functions
    function getFirebaseUIObject(){
        const firebaseAuth = FirebaseService.auth;
        return auth.AuthUI.getInstance() || new auth.AuthUI(firebaseAuth);
    }

    

    return (
        <>
            <div style={{"margin": "5em"}} id="firebaseui-auth-container">

            </div>
        </>
    );
}