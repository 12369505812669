import './App.css';
import 'primereact/resources/themes/fluent-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'
import log from 'loglevel';
import MainComponent from './components/main-component'
import { UserProvider } from "./context/user-context"
import { DiagramProvider } from './context/diagram-context';
import {useEffect} from 'react';
import { UtilsProvider } from './context/utils-context';

function App() {

    useEffect(() => {
        
        document.title = "Atmos"
        log.setLevel('debug')
    }, []);


    return (
        <div className="App">
            <UserProvider>
                <DiagramProvider>
                    <UtilsProvider>
                        <MainComponent></MainComponent>
                    </UtilsProvider>
                </DiagramProvider>
            </UserProvider>
        </div>
    );
}

export default App;
