
import { Dialog } from 'primereact/dialog';
import { useContext } from 'react';
import DiagramContext from '../../../context/diagram-context';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import DiagramBuilderService from '../../../services/diagram/diagram-builder-service';
import { useEffect } from 'react';
import { ReactFlowProvider } from 'reactflow';
import ResourceCreationDialog from './resource-creation-dialog';
import FunctionForm from '../resource-forms/function-form';


export default function CreateFunctionDialog() {

    // App Context
    const {
        atmosResources, setAtmosResources,
        diagramDialogsDisplay, setDiagramDialogsDisplay,
        resourceData, setResourceData,
        setDiagramNodes, setDiagramEdges
    } = useContext(DiagramContext);
    // State
    const cleanFunctiondata = {
        "name": "",
        "resourceType": "function",
        "subType": "default"
    }

    // Hooks
    // Variables
    
    // Functions
    function onDialogShow() {
        setResourceData(cleanFunctiondata)
    }

    function hideDialog() {
        setDiagramDialogsDisplay(
            {...diagramDialogsDisplay, function: false}
        )
    }

    function onCreate(){

        const result = DiagramBuilderService.addFunction(atmosResources, resourceData)
        setAtmosResources(result.updatedResourceList)
        setDiagramNodes(result.nodes)
        setDiagramEdges(result.edges)
        hideDialog();
        setResourceData(undefined);
    }

    // Html

    return (
        <ResourceCreationDialog
            onDialogShow={onDialogShow}
            title="Create new Function"
            dialogDisplay={diagramDialogsDisplay.function}
            onHide={hideDialog}
            onCreate={onCreate}
            body={
                <FunctionForm></FunctionForm>
            }
        >
        </ResourceCreationDialog>
    );
}