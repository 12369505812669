import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import LoginPage from './pages/login-page';
import HomePage from './pages/home-page';
import DiagramPage from './pages/diagram-page';
import ProjectInfoPage from './pages/project-info-page';
import PrivateRoutes from './components/routing/private-routes';
import ExportPage from './pages/export-page';
import ExportResultPage from './pages/export-result-page';
import DiagramDashboardPage from './pages/diagram-dashboard-page';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="login" element={<LoginPage />} />
                <Route element={<PrivateRoutes/>}>
                    <Route path="" element={<HomePage />} />
                    <Route path="project/:id" element={<ProjectInfoPage />} />
                    <Route path="diagram/:id" element={<DiagramPage />} />
                    <Route path="export/:id" element={<ExportPage />} />
                    <Route path="export-result/:id" element={<ExportResultPage />} />
                    <Route path="diagram-dashboard/:id" element={<DiagramDashboardPage />} />
                </Route>
            </Route>
        </Routes>
    </BrowserRouter>
);
