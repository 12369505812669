import {useContext, useEffect, useState} from 'react';
import UserContext from '../context/user-context';
import log from 'loglevel';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProjectsFunctions from '../services/functions/projects-functions';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { TbEdit } from "react-icons/tb";
import { TbX } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';

export default function HomePage() {

    // Context
    const {user} = useContext(UserContext);

    // State
    const [displayCreateDialog, setDisplayCreateDialog] = useState(false);
    const [newProjectName, setNewProjectName] = useState("");
    const [newProjectGithubAuth, setNewProjectGithubAuth] = useState("");
    const [newProjectOwner, setNewProjectOwner] = useState(user.id);
    const [projectList, setProjectList] = useState([]);

    useEffect(() => {
        log.debug("[Rendering Page] Home")
        sessionStorage.setItem("desiredPath", window.location.pathname);
        refreshProjectList();
    }, [])
    // Hooks
    // Variables
    const navigate = useNavigate();
    // Functions
    async function refreshProjectList() {
        const response = await ProjectsFunctions.getProjectsByOwner(user.id)
        if(response.status == 200){
            setProjectList(response.data);
        }
        if(response.status == 204){
            setProjectList([]);
        }
    }

    function clearNewProjectData() {
        setNewProjectName("");
    }

    async function createNewProject() {
        return await ProjectsFunctions.createProject(newProjectName, newProjectOwner, newProjectGithubAuth)
    }

    // Html

    const dialogFooter = () => {
        return (
            <div>
                <Button label="Create" className="p-button-success" onClick={async () => {
                    const response = await createNewProject();
                    setDisplayCreateDialog(false)
                    clearNewProjectData();
                    await refreshProjectList();
                }} autoFocus />
            </div>
        );
    }

    const projectsAction = (rowData) => {
        
        return <Button className="p-button-rounded p-button-danger" icon="pi pi-times"
            onClick={async () => {
                await ProjectsFunctions.deleteProjectById(rowData.id);
                await refreshProjectList();
            }}></Button>;
    }

    


    return (
        <>
            <div className='home-panel'>
                <div style={{"fontSize": "5em"}}>Welcome to Atmos</div>
                <div style={{"fontSize": "2em", "marginLeft": "1em" }}>Let&rsquo;s start creating!</div>
                <Button 
                    onClick={() => {setDisplayCreateDialog(true)}}
                    label='Create New Project' 
                    className="p-button-outlined"
                    style={{"margin": "2em" }}>
                </Button>

                <DataTable className='general-list' showGridlines size="small" value={projectList} responsiveLayout="scroll"
                    onRowClick={(e) => { navigate("/project/"+e.data.id); }}>
                    <Column field="name" header="Name"></Column>
                    <Column field="id" header="Id"></Column>
                    <Column field="ownerId" header="Owner Id"></Column>
                    <Column body={projectsAction} header="Action"></Column>
                </DataTable>
            </div>

            <Dialog 
                header="Create New Project" 
                style={{ "width": '30%', "height": "30%" }}
                className="general-dialog"
                draggable={false} 
                visible={displayCreateDialog} onHide={() => {setDisplayCreateDialog(false)}}
                footer={dialogFooter} 
            >
                <div style={{"margin": "auto", "width": "50%"}}>
                    <InputText placeholder='Project Name' value={newProjectName} onChange={(e) => setNewProjectName(e.target.value)} />
                    <InputText placeholder='Github Auth' value={newProjectGithubAuth} onChange={(e) => setNewProjectGithubAuth(e.target.value)} />
                </div>
            </Dialog>
        </>
    );
}
