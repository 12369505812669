
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { TreeTable } from 'primereact/treetable';
import { useContext } from 'react';
import { useState } from 'react';
import DiagramContext from '../../../context/diagram-context';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';

export default function LinkForm() {

    // App Context
    const {
        resourceData, setResourceData, atmosResources
    } = useContext(DiagramContext);
    // State
    // Hooks
    // Variables
    // Functions
    function getSourceNames() {
        const names = atmosResources.map((r) => {
            return r.name
        })
        return names;
    }

    function getTargetNames() {
        const names = atmosResources.map((r) => {
            return r.name
        })
        return names;
    }

    function getLinkTypes() {
        return[
            "dbManipulation"
        ]
    }
     

    return (
        <>
            <div>
                {/* Source */}
                <Dropdown value={resourceData?.sourceName} options={getSourceNames()} onChange={(e) => {
                    setResourceData(
                        {...resourceData, sourceName: e.value}
                    )
                }} placeholder="Select the Source"/>

                {/* Target */}
                <Dropdown value={resourceData?.targetName} options={getTargetNames()} onChange={(e) => {
                    setResourceData(
                        {...resourceData, targetName: e.value}
                    )
                }} placeholder="Select the Target"/>

                {/* Target */}
                <Dropdown value={resourceData?.type} options={getLinkTypes()} onChange={(e) => {
                    setResourceData(
                        {...resourceData, type: e.value}
                    )
                }} placeholder="Select the Link Type"/>
            </div>
        </>        
    );
}