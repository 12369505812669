import { useContext } from "react";
import DiagramContext from "../../../context/diagram-context";
import DiagramBuilderService from "../../../services/diagram/diagram-builder-service";
import DatabaseForm from "../resource-forms/database-form";
import ResourceCreationDialog from "./resource-creation-dialog";
import LinkForm from "../resource-forms/link-form"

export default function CreateLinkDialog() {

    // App Context
    const {
        atmosResources, setAtmosResources,
        diagramDialogsDisplay, setDiagramDialogsDisplay,
        resourceData, setResourceData,
        setDiagramNodes, setDiagramEdges
    } = useContext(DiagramContext);
    // State
    const cleanLinkData = {
        "sourceName": "",
        "targetName": "",
        "sourceId": "",
        "targetId": "",
        "type": ""
    }

    // Hooks
    // Variables
    
    // Functions
    function onDialogShow() {
        setResourceData(cleanLinkData)
    }

    function hideDialog() {
        setDiagramDialogsDisplay(
            {...diagramDialogsDisplay, link: false}
        )
    }

    function onCreate(){

        const result = DiagramBuilderService.addLink(atmosResources, resourceData);
        setAtmosResources(result.updatedResourceList);
        setDiagramNodes(result.nodes);
        setDiagramEdges(result.edges);
        hideDialog();
        setResourceData(undefined);
    }

    // Html

    return (
        <ResourceCreationDialog
            onDialogShow={onDialogShow}
            title="Create new Link"
            dialogDisplay={diagramDialogsDisplay.link}
            onHide={hideDialog}
            onCreate={onCreate}
            body={
                <LinkForm></LinkForm>
            }
        >
        </ResourceCreationDialog>
    );
}