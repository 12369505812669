import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ReactFlow, { addEdge, applyEdgeChanges, applyNodeChanges } from 'react-flow-renderer';
import DiagramContext from '../context/diagram-context';
import DiagramBuilderService from '../services/diagram/diagram-builder-service';
import { ContextMenu } from 'primereact/contextmenu';
import log from 'loglevel';
import CreateAtmosDatabaseForm from '../components/diagram/dialogs/create-database-dialog';
import CreateFunctionDialog from '../components/diagram/dialogs/create-function-dialog';
import DiagramFunctions from '../services/functions/diagram-functions';
import { useParams } from 'react-router-dom';
import FunctionNode from '../components/diagram/custom-nodes/function-node';
import { useMemo } from 'react';
import { ReactFlowProvider } from 'reactflow';
import DatabaseNode from '../components/diagram/custom-nodes/database-node';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import DiagramLeftPanel from '../components/diagram/panels/diagram-left-panel';
import DiagramRightPanel from '../components/diagram/panels/diagram-right-panel';
import CreateDatabaseDialog from '../components/diagram/dialogs/create-database-dialog';
import CreateLinkDialog from '../components/diagram/dialogs/create-link-dialog';
import Utils from '../services/utils/utils';
import UtilsContext from '../context/utils-context';

const nodeTypes = {
    functionNode: FunctionNode, databaseNode: DatabaseNode
};

export default function DiagramPage() {

    // App Context
    const {
        atmosResources, setAtmosResources,
        currentDiagramInfo, setCurrentDiagramInfo,
        diagramNodes, setDiagramNodes,
        diagramEdges, setDiagramEdges,
        setDiagramDialogsDisplay, setResourceData
    } = useContext(DiagramContext);

    const {toast} = useContext(UtilsContext);


    // State
    // Hooks
    useEffect(() => {

        log.debug("[Rendering Page] Diagram")
        sessionStorage.setItem("desiredPath", window.location.pathname);
        // Clean current resource data
        setResourceData(undefined)
        setAtmosResources([])
        // Get Diagram Data From Server. Both info and resource list
        getDiagramData()
        
    }, [])

    const { id: diagramId } = useParams();
    // Variables
    const contextMenuReference = useRef(null);

    // Functions
    async function getDiagramData() {
        const response = await DiagramFunctions.getDiagramInfoById(diagramId)
        if(response.status == 200){
            log.debug("Diagram Data from server: ", response.data)
            setCurrentDiagramInfo(response.data)
            if(response.data.resources) setAtmosResources(response.data.resources)
            const result = DiagramBuilderService.getNodesAndEdgesFromResourceList(response.data.resources)
            setAtmosResources(response.data.resources)
            setDiagramNodes(result.nodes)
            setDiagramEdges(result.edges)
        }
    }

    // Html
    const menuContextItems = [
       
        {
            label:'New Function',
            icon:'pi pi-fw pi-sign-in',
            command: (event) => {
                setDiagramDialogsDisplay(Utils.showDialog("function"))
            }
        },
        {
            label:'New Database',
            icon:'pi pi-fw pi-box',
            command: (event) => {
                setDiagramDialogsDisplay(Utils.showDialog("database"))
            }
        },
        {
            label:'New Link',
            icon:'pi pi-fw pi-link',
            command: (event) => {
                setDiagramDialogsDisplay(Utils.showDialog("link"))
            }
        },
        {
            label:'Save',
            icon:'pi pi-fw pi-save',
            command: async (event) => {
                const response = await DiagramFunctions.updateDiagram({...currentDiagramInfo, resources: atmosResources});
                if(response.status == 200){
                    toast.current.show({severity:'success', summary: 'Diagram Saved', life: 2000});
                }
            }
        }
    ];

    // React Flow Hooks
    const onNodesChange = useCallback(
        (changes) => setDiagramNodes((nds) => applyNodeChanges(changes, nds)),
        [setDiagramNodes]
    );
    const onEdgesChange = useCallback(
        (changes) => setDiagramEdges((eds) => applyEdgeChanges(changes, eds)),
        [setDiagramEdges]
    );
    const onConnect = useCallback(
        (connection) => setDiagramEdges((eds) => addEdge(connection, eds)),
        [setDiagramEdges]
    );
    const onNodeClick = useCallback(
        (event, node) => {
            const resource = atmosResources.find(x => x.id == node.id);
            setResourceData(resource)
        }
    );
    const onNodeDragStop = useCallback(
        (event, node) => {
            const updatedResourceList = DiagramBuilderService.updateNodePosition(atmosResources, node.id, node.position)
            setAtmosResources(updatedResourceList)
        },
        [atmosResources]
    );

    return (
        <>
            
            {/* Diagram */}
            <div id="diagram-container" 
                onContextMenu={(e) => contextMenuReference.current.show(e)}
                className="diagram">
                        
                <ReactFlowProvider>
                    <ReactFlow 
                        fitView
                        nodes={diagramNodes} edges={diagramEdges}  
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onNodeClick={onNodeClick}
                        onNodeDragStop={onNodeDragStop}
                        nodeTypes={nodeTypes}
                    />
                </ReactFlowProvider>
                    
            </div>

            {/* Dialogs */}
            <CreateFunctionDialog></CreateFunctionDialog>
            <CreateDatabaseDialog></CreateDatabaseDialog>
            <CreateLinkDialog></CreateLinkDialog>

            {/* Panels */}
            <DiagramLeftPanel></DiagramLeftPanel>
            <DiagramRightPanel></DiagramRightPanel>

            {/* Context Menus */}
            <ContextMenu model={menuContextItems} ref={contextMenuReference}></ContextMenu>
        </>
    );
}