
import { Navigate, Outlet } from "react-router-dom";
import UserContext from "../../context/user-context";
import { useContext } from 'react';
import HttpService from "../../services/http/http-service";
import log from 'loglevel';

export default function PrivateRoutes() {
    
    function isLogged(){
        log.debug(`Checking if the user is logged, desired path: ${window.location.pathname}`)
        sessionStorage.setItem("desiredPath", window.location.pathname);
        const jwt = HttpService.getJwt();
        const isLogged = jwt != "";
        return isLogged;
    }

    return (
        isLogged() ? <Outlet/> : <Navigate to='/login'/>
    );
}