import axios from "axios";
import log from 'loglevel';

let currentJwt = "";

axios.interceptors.request.use(
    config => {
        if (currentJwt) {
            config.headers['Authorization'] = 'Bearer ' + currentJwt
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)

const HttpService = {

    setJwt(jwt){
        currentJwt = jwt;
    },

    getJwt(){
        return currentJwt;
    },

    async get(url){
        log.debug("[GET] " + url);
        let response = await axios.get(url);
        log.debug(`[GET](${response.status}) ${url}`)
        return response;
    },

    async post(url, data){
        log.debug("[POST] " + url);
        const response = await axios.post(url, data);
        log.debug(`[POST](${response.status}) ${url}`)
        return response;
    },

    async put(url, data){
        log.debug("[PUT] " + url);
        const response = await axios.put(url, data);
        log.debug(`[PUT](${response.status}) ${url}`)
        return response;
    },

    async delete(url){
        log.debug("[DELETE] " + url);
        const response = await axios.delete(url);
        log.debug(`[DELETE](${response.status}) ${url}`)
        return response;
    },

    downloadFile(url, fileName){
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
    }
}

export default HttpService;