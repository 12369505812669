
import { useContext } from 'react';
import DiagramContext from '../../../context/diagram-context';
import { Button } from 'primereact/button';
import DiagramBuilderService from '../../../services/diagram/diagram-builder-service';
import { DataTable } from 'primereact/datatable';
import Utils from '../../../services/utils/utils';
import { Column } from 'primereact/column';

export default function LinksTable() {

    // App Context
    const {
        atmosResources, setAtmosResources,
        diagramDialogsDisplay, setDiagramDialogsDisplay,
        resourceData, setResourceData,
        setDiagramNodes, setDiagramEdges
    } = useContext(DiagramContext);
    // State
   
    // Hooks
    // Variables
    
    // Functions
    
    // Html
    const deleteButton = (rowData) => {
        return <Button type="button" icon="pi pi-minus" className="p-button-danger"
            onClick={() => {
                const result = DiagramBuilderService.removeLink(atmosResources, rowData.sourceId, rowData.id)
                setAtmosResources(result.updatedResourceList);
                setDiagramNodes(result.nodes);
                setDiagramEdges(result.edges);
            }}></Button>;
    }

    return (
        <>
            <div style={{"fontSize": "1.5em", "margin": "1em", "textAlign": "center"}}>Links</div>
            <Button type="button" icon="pi pi-plus" className="p-button-outlined p-button-success" label='Add Link'
                onClick={() => {
                    setDiagramDialogsDisplay(Utils.showDialog("link"))
                }}>
            </Button>
            <DataTable 
                value={resourceData?.links} 
                responsiveLayout="scroll"
                stripedRows>

                <Column field="type" header="Type"></Column>
                <Column field="targetName" header="Target"></Column>
                <Column body={deleteButton} header="Actions"></Column>
            </DataTable>
        </>
    );
}