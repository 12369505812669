import { createContext, useState } from "react";

const DiagramContext = createContext();

export function DiagramProvider({children}){

    // Diagram info, name, id etc
    const [currentDiagramInfo, setCurrentDiagramInfo] = useState({});

    // Nodes and edges used by React Flow
    const [diagramNodes, setDiagramNodes] = useState([]);
    const [diagramEdges, setDiagramEdges] = useState([]);

    // Dictionary to Show Multiple Dialogs
    const [diagramDialogsDisplay, setDiagramDialogsDisplay] = useState({
        "database": false,
        "function": false,
        "link": false
    });

    // Atmos Resources being edit by resource editor
    const [atmosResources, setAtmosResources] = useState([])

    // Resource data, used to add new / edit
    const [resourceData, setResourceData] = useState(undefined);

    return (
        <DiagramContext.Provider value={
            { 
                currentDiagramInfo, setCurrentDiagramInfo,
                diagramNodes, setDiagramNodes,
                diagramEdges, setDiagramEdges,
                diagramDialogsDisplay, setDiagramDialogsDisplay,
                atmosResources, setAtmosResources,
                resourceData, setResourceData
            }
        }>{children}</DiagramContext.Provider>
    )
}


export default DiagramContext;

