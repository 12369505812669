import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import log from 'loglevel';
import {useNavigate} from "react-router-dom";
import DiagramFunctions from '../services/functions/diagram-functions';
import ProjectsFunctions from '../services/functions/projects-functions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BreadCrumb } from 'primereact/breadcrumb';

export default function ProjectInfoPage() {
    
    // App Context

    // State
    const [projectInfo, setProjectInfo] = useState();
    const [displayCreateDialog, setDisplayCreateDialog] = useState(false);
    const [newDiagramName, setNewDiagramName] = useState("");
    const [diagramList, setDiagramList] = useState([]);

    // Hooks
    useEffect(() => {
        log.debug("[Rendering Page] Project Info")
        sessionStorage.setItem("desiredPath", window.location.pathname);
        initialize()
    }, [])

    // Variables
    const { id: projectId } = useParams();
    const navigate = useNavigate()
    const breadMenuItems = [
        {label: 'Project'},
    ];

    // Functions
    async function initialize(){
        log.debug("[Log] Retrieving Project Info and Diagram list from server")
        await refreshProjectInfo();
        await refreshDiagramList();
    }

    const refreshDiagramList = async function () {
        const response = await DiagramFunctions.getDiagramsByProjectId(projectId);
        if(response.status == 200){
            setDiagramList(response.data);
        }
        if(response.status == 204){
            setDiagramList([]);
        }
    }

    const refreshProjectInfo = async function () {
        const response = await ProjectsFunctions.getProjectsById(projectId);
        if(response.status == 200){
            setProjectInfo(response.data);
        }
    }

    function clearNewDiagramData() {
        setNewDiagramName("");
    }

    async function createNewProject() {
        return await DiagramFunctions.createDiagram(newDiagramName, projectId)
    }

    // Html
    const dialogFooter = () => {
        return (
            <div>
                <Button label="Create" className="p-button-success" onClick={async () => {
                    const response = await createNewProject();
                    log.debug(response);
                    setDisplayCreateDialog(false)
                    clearNewDiagramData();
                    refreshDiagramList();
                }} autoFocus />
            </div>
        );
    }

    const home = { icon: 'pi pi-home', command: () => {navigate("/")} }

    const projectsAction = (rowData) => {
        
        return <Button className="p-button-rounded p-button-danger" icon="pi pi-times"
            onClick={async () => {
                await DiagramFunctions.deleteDiagramById(rowData.id);
                await refreshDiagramList();
            }}></Button>;
    }

    return (
        <>
            <div className='bread-menu'>
                <BreadCrumb style={{
                // backgroundColor: "red"
                }} model={breadMenuItems} home={home} />
            </div>
            <div className='project-details-panel'>
                <div style={{"fontSize": "3em"}}>{projectInfo ? projectInfo.name : "Project Name"}</div>

                <Button 
                    onClick={() => {setDisplayCreateDialog(true)}}
                    label='Create New Diagram' 
                    className="p-button-outlined p-button-secondary"
                    style={{"margin": "2em" }}>
                </Button>

                <DataTable className='general-list' showGridlines size="small" value={diagramList} responsiveLayout="scroll"
                    onRowClick={(e) => { navigate("/diagram/"+e.data.id); }}>
                    <Column field="name" header="Name"></Column>
                    <Column field="id" header="Id"></Column>
                    <Column body={projectsAction} header="Action"></Column>
                </DataTable>



            </div>

            <Dialog 
                header="Create New Diagram" 
                visible={displayCreateDialog} 
                style={{ "width": '30%', "height": "30%" }}
                footer={dialogFooter} 
                onHide={() => {setDisplayCreateDialog(false)}}>
                <div style={{"margin": "auto", "width": "50%"}}>
                    <InputText placeholder='Diagram Name' value={newDiagramName} onChange={(e) => setNewDiagramName(e.target.value)} />
                </div>
                
            </Dialog>
        </>
    );
}