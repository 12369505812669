import HttpService from "../http/http-service"
import log from 'loglevel';

const UsersFunctions = {

    async getUserByFirebaseId(firebaseId){
        return await HttpService.get(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/users/firebase-id/" + firebaseId)
    }
}

export default UsersFunctions;