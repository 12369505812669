import { useContext } from "react";
import DiagramContext from "../../../context/diagram-context";
import DiagramBuilderService from "../../../services/diagram/diagram-builder-service";
import DatabaseForm from "../resource-forms/database-form";
import ResourceCreationDialog from "./resource-creation-dialog";

export default function CreateDatabaseDialog() {

    // App Context
    const {
        atmosResources, setAtmosResources,
        diagramDialogsDisplay, setDiagramDialogsDisplay,
        resourceData, setResourceData,
        setDiagramNodes, setDiagramEdges
    } = useContext(DiagramContext);
    // State
    const cleanDatabaseData = {
        "name": "",
        "resourceType": "database",
        "subType": "noSql"
    }

    // Hooks
    // Variables
    
    // Functions
    function onDialogShow() {
        setResourceData(cleanDatabaseData)
    }

    function hideDialog() {
        setDiagramDialogsDisplay(
            {...diagramDialogsDisplay, database: false}
        )
    }

    function onCreate(){

        const result = DiagramBuilderService.addDatabase(atmosResources, resourceData)
        setAtmosResources(result.updatedResourceList)
        setDiagramNodes(result.nodes)
        setDiagramEdges(result.edges)
        hideDialog();
        setResourceData(undefined);
    }

    // Html

    return (
        <ResourceCreationDialog
            onDialogShow={onDialogShow}
            title="Create new Database"
            dialogDisplay={diagramDialogsDisplay.database}
            onHide={hideDialog}
            onCreate={onCreate}
            body={
                <DatabaseForm></DatabaseForm>
            }
        >
        </ResourceCreationDialog>
    );
}