
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export default function ResourceCreationDialog(props) {

    return (
        <Dialog 
            onShow={props.onDialogShow} 
            header={props.title} 
            visible={props.dialogDisplay} 
            style={{ width: '80vw' }} 
            onHide={props.onHide}>
            {props.body}          
            <Button label='Create' onClick={props.onCreate} />      
        </Dialog>
        
    );
}