import { Menubar } from 'primereact/menubar';
import {useNavigate} from "react-router-dom";
import { Button } from 'primereact/button';
import FirebaseService from "../../services/firebase/firebase-service"
import UserContext from '../../context/user-context';
import { useContext } from 'react';
import { TbLogout } from "react-icons/tb";
import { Avatar } from 'primereact/avatar';
import HttpService from '../../services/http/http-service';


export default function TopMenu() {

    // App Context
    const {setUser} = useContext(UserContext);

    // State
    // Hooks
    const navigate = useNavigate();

    // Variables
    const items = [
        {
            label: 'Atmos',
            command: () => {navigate("/")}
        },
        {
            label:'Projects',
            command: () => {navigate("/projects")}
        },
    ];
    // Functions
    function logout(){
        const firebaseAuth = FirebaseService.auth;
        firebaseAuth.signOut();
        setUser({})
        HttpService.setJwt("")
    }

    // HTML
    const end = <>
        <div>Version: 3 ({process.env.REACT_APP_ENV})</div>
        {/* <Button onClick={logout} className="p-button-rounded p-button-text"><GrLogout/></Button> */}
    </>
    

    return (
        <>
            <div className='top-menu'>
                <img onClick={() => {navigate("/")}} className='atmos-logo-top-icon' src={"./atmos-black-icon.png"}/>
                {/* <Avatar label="P" 
                    style={{ backgroundColor: '#2196F3', color: '#ffffff' }} className="mr-2 top-avatar" size="large" shape="circle" /> */}
                <TbLogout onClick={() => {logout()}} className='logout-top-icon'></TbLogout>
            </div>
            {/* <Menubar className='top-menu' model={items} start={start} end={end}/> */}
        </>
    );
}