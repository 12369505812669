import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DiagramContext from "../context/diagram-context";
import { ListBox } from 'primereact/listbox';
import { useState } from "react";
import { useEffect } from "react";
import log from 'loglevel';
import ExportFunctions from "../services/functions/export-functions";
import DiagramFunctions from "../services/functions/diagram-functions";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import FirebaseService from "../services/firebase/firebase-service";
import { ref, getDownloadURL } from "firebase/storage";
import HttpService from "../services/http/http-service";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { BreadCrumb } from 'primereact/breadcrumb';

export default function ExportResultPage(){

    // App Context
    // State
    const [exportData, setExportData] = useState(undefined);
    
    // Hooks
    useEffect(() => {
        log.debug("[Rendering Page] Export Page")
        sessionStorage.setItem("desiredPath", window.location.pathname);
        initialize()
    }, [])
    const { id: exportId } = useParams();
    const navigate = useNavigate();

    // Variables
    const breadMenuItems = [
        {label: 'Project', command: () => {navigate(`/project/${exportData.projectId}`)}},
        {label: 'Diagram', command: () => {navigate(`/diagram/${exportData.diagramId}`)}},
        {label: 'Dashboard', command: () => {navigate(`/diagram-dashboard/${exportData.diagramId}`)}},
        {label: 'Exports', command: () => {navigate(`/export/${exportData.id}`)}},
        {label: 'Export Result'},
    ];

    // Functions
    async function initialize(){
        log.debug("Retrieving Exports of the diagram from the server")
        await getExportData(exportId);
    }

    async function getExportData(exportId) {
        const response = await ExportFunctions.getExportById(exportId)
        console.log(response.data);
        setExportData(response.data)
    }

    async function downloadTemplate(exportId){
        log.debug("Downloading Template")
        const fileName = `gcp_template_${exportId}.yaml`;
        const fileRef = ref(FirebaseService.storage, fileName);
        const url = await getDownloadURL(fileRef);
        await HttpService.downloadFile(url, fileName);
    }

    // Html

    const exportAction = (rowData) => {
        
        return <Button className="p-button-rounded" icon="pi pi-arrow-down"
            onClick={() => {
                if(rowData.type == "create_gcp_template"){
                    downloadTemplate(exportId)
                }
            }}></Button>;
    }
    const home = { icon: 'pi pi-home', command: () => {navigate("/")} }

    return(
        <>
            <div className='bread-menu'>
                <BreadCrumb style={{
                    // backgroundColor: "red"
                }} model={breadMenuItems} home={home} />
            </div>
            <div className="export-panel">
                    
                <h1 style={{"fontSize": "3em"}}>Let&rsquo;s see how {exportData?.name} went!</h1>
                <Button label="Refresh" onClick={async () => {
                    await getExportData(exportId);
                }}></Button>
                <Divider></Divider>
                <DataTable className='general-list' showGridlines size="small" value={exportData?.actions} responsiveLayout="scroll">
                    <Column field="type" header="Type"></Column>
                    <Column field="status" header="Status"></Column>
                    <Column body={exportAction} header="Action"></Column>
                </DataTable>
                            
            </div>
        </>
    )
}