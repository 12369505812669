import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DiagramContext from "../context/diagram-context";
import { ListBox } from 'primereact/listbox';
import { useState } from "react";
import { useEffect } from "react";
import log from 'loglevel';
import ExportFunctions from "../services/functions/export-functions";
import DiagramFunctions from "../services/functions/diagram-functions";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import FirebaseService from "../services/firebase/firebase-service";
import { ref, getDownloadURL } from "firebase/storage";
import HttpService from "../services/http/http-service";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { BreadCrumb } from 'primereact/breadcrumb';

export default function DiagramDashboardPage(){

    // App Context
    // State
    const [diagramData, setDiagramData] = useState();

    // Hooks
    useEffect(() => {
        log.debug("[Rendering Page] Diagram Dashboard Page")
        sessionStorage.setItem("desiredPath", window.location.pathname);
        initialize()
    }, [])
    const { id: diagramId } = useParams();

    // Variables
    const navigate = useNavigate();
    const breadMenuItems = [
        {label: 'Project', command: () => {navigate(`/project/${diagramData.projectId}`)}},
        {label: 'Diagram', command: () => {navigate(`/diagram/${diagramData.id}`)}},
        {label: 'Dashboard'},
    ];
    // Functions
    async function initialize(){
        const diagramResponse = await DiagramFunctions.getDiagramInfoById(diagramId)
        if(diagramResponse.status == 200){
            log.debug("Diagram Data from server: ", diagramResponse.data)
            setDiagramData(diagramResponse.data)
        }
    }

    // Html
    const home = { icon: 'pi pi-home', command: () => {navigate("/")} }
    return(
        <>
            <div className='bread-menu'>
                <BreadCrumb style={{
                    // backgroundColor: "red"
                }} model={breadMenuItems} home={home} />
            </div>
            <div className="diagram-dashboard-panel">
                
                <h1>{diagramData?.name} Dashboard</h1>    

                <Button label="Export Diagram to the Cloud" type="button" className="p-button-help"
                    onClick={() => {
                        navigate("/export/"+diagramId)
                    }}>
                </Button>
            </div>
        </>
    )
}