
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { TreeTable } from 'primereact/treetable';
import { useContext } from 'react';
import { useState } from 'react';
import DiagramContext from '../../../context/diagram-context';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';


export default function DatabaseForm() {

    // App Context
    const {
        resourceData, setResourceData
    } = useContext(DiagramContext);
    // State
    // Hooks
    // Variables
    const functionSubTypes = [
        "noSql",
        "Sql"
    ]
    // Functions
    

    return (
        <>
            <div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Name</span>
                    <InputText value={resourceData?.name} onChange={(e) => {
                        setResourceData(
                            {...resourceData, name: e.target.value}
                        )}
                    } />
                </div>

                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Type</span>
                    <Dropdown value={resourceData?.subType} options={functionSubTypes} onChange={(e) => {
                        setResourceData(
                            {...resourceData, subType: e.value}
                        )
                    }}/>
                </div>
                

                
            </div>
        </>        
    );
}