import { createContext, useState } from "react";

const UtilsContext = createContext();

export function UtilsProvider({children}){

    const [toast, setToast] = useState({});

    return (
        <UtilsContext.Provider value={
            { 
                toast, setToast,
            }
        }>
            {children}</UtilsContext.Provider>
    )
}


export default UtilsContext;
