import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import log from 'loglevel';

var firebaseConfig = {
    apiKey: "AIzaSyC-IDObgPnz1uFqxDYMuqFMYaLGTS_XcI0",
    authDomain: "atmos-44b39.firebaseapp.com",
    projectId: "atmos-44b39",
    storageBucket: "atmos-44b39.appspot.com",
    messagingSenderId: "36549587955",
    appId: "1:36549587955:web:baf30a967f6ae21f491886",
    measurementId: "G-G4HHS02LER"
};


// Initialization app
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

if(process.env.REACT_APP_ENV == "DEV"){
    log.debug("Connecting to Emulators")
    connectAuthEmulator(auth, "http://localhost:9099");
    connectStorageEmulator(storage, "localhost", 9199)
}

const FirebaseService = {

    app: app,
    auth: auth,
    storage: storage
}

export default FirebaseService;