import HttpService from "../http/http-service"
import log from 'loglevel';

const DiagramFunctions = {

    async createDiagram(name, projectId){
        return await HttpService.post(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/diagrams", {
            "name": name,
            "projectId": projectId
        })
    },

    async getDiagramsByProjectId(projectId){
        return await HttpService.get(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/diagrams/project/"+projectId)
    },

    async getDiagramInfoById(id){
        return await HttpService.get(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/diagrams/" + id)
    },

    async updateDiagram(diagramInfo){
        return await HttpService.put(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/diagrams/" + diagramInfo.id, diagramInfo)
    },

    async deleteDiagramById(diagramId){
        return await HttpService.delete(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/diagrams/" + diagramId)
    }
}

export default DiagramFunctions;