import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DiagramContext from "../context/diagram-context";
import { ListBox } from 'primereact/listbox';
import { useState } from "react";
import { useEffect } from "react";
import log from 'loglevel';
import ExportFunctions from "../services/functions/export-functions";
import DiagramFunctions from "../services/functions/diagram-functions";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import FirebaseService from "../services/firebase/firebase-service";
import { ref, getDownloadURL } from "firebase/storage";
import HttpService from "../services/http/http-service";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { BreadCrumb } from 'primereact/breadcrumb';

export default function ExportPage(){

    // App Context

    // State
    const [diagramData, setDiagramData] = useState(undefined);
    const [exportList, setExportList] = useState([]);
    const [selectedExportFromList, setSelectedExportFromList] = useState(undefined);


    // Dialog
    const [displayCreateDialog, setDisplayCreateDialog] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [exportName, setExportName] = useState("")
    
    // Hooks
    useEffect(() => {
        log.debug("[Rendering Page] Export Page")
        sessionStorage.setItem("desiredPath", window.location.pathname);
        initialize()
    }, [])
    const { id: diagramId } = useParams();
    const navigate = useNavigate();

    // Variables
    const templates = [
        { name: 'Gcp Export', code: 'export_gcp_cloud' },
        // { name: 'Azure Template', code: 'create_azure_template' },
        // { name: 'Aws Template', code: 'create_aws_template' },
    ];
    const breadMenuItems = [
        {label: 'Project', command: () => {navigate(`/project/${diagramData.projectId}`)}},
        {label: 'Diagram', command: () => {navigate(`/diagram/${diagramData.id}`)}},
        {label: 'Dashboard', command: () => {navigate(`/diagram-dashboard/${diagramData.id}`)}},
        {label: 'Exports'},
    ];
    
    // Functions
    async function initialize(){
        log.debug("Retrieving Exports of the diagram from the server")
        const diagramReponse = await DiagramFunctions.getDiagramInfoById(diagramId);
        setDiagramData(diagramReponse.data);
        await refreshExportData()
    }

    /**
     * Get Export data from the server and fill the
     */
    async function refreshExportData(){
        const exportResponse = await ExportFunctions.getExportByDiagramId(diagramId)
        if(exportResponse.status == 200){
            // const list = exportResponse.data.map( (exportData) => {
            //     return {name: exportData.id, code: exportData.id}
            // })
            setExportList(exportResponse.data);
        }
        
    }

    // Html
    const home = { icon: 'pi pi-home', command: () => {navigate("/")} }

    const dialogFooter = () => {
        return (
            
            <div>           
                <Button label="Export" className="p-button-success" onClick={async () => {
                    const actions = [
                        {
                            "type": "export_gcp_cloud"
                        }
                    ]
                    const response = await ExportFunctions.createExport(diagramId, actions, exportName)
                    await refreshExportData()
                    setDisplayCreateDialog(false)
                }} autoFocus />
            </div>
        );
    }

    return(
        <>
            <div className='bread-menu'>
                <BreadCrumb style={{
                    // backgroundColor: "red"
                }} model={breadMenuItems} home={home} />
            </div>
            <div className="export-panel">
                <h1 style={{"fontSize": "3em"}}>Let&rsquo;s export {diagramData?.name}?</h1>

                <div>
                    <Button label="Export" onClick={() => {setDisplayCreateDialog(true)}}></Button>

                    <h2>Finished Exports</h2>

                    <DataTable className='general-list' showGridlines size="small" value={exportList} responsiveLayout="scroll"
                        onRowClick={(e) => { navigate("/export-result/"+e.data.id); }}>
                        <Column field="name" header="Name"></Column>
                        <Column field="id" header="Id"></Column>
                    </DataTable>
                </div>
            </div>
           

            <Dialog 
                header="Export Diagram" visible={displayCreateDialog} 
                style={{ "width": '50%', "height": "50%" }}
                footer={dialogFooter} onHide={() => {setDisplayCreateDialog(false)}}>
                
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Name</span>
                    <InputText value={exportName} onChange={(e) => setExportName(e.target.value)} />
                </div>


                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Export to Cloud Template</span>
                    <MultiSelect 
                        optionLabel="name" display="chip"
                        value={selectedTemplates} 
                        options={templates} 
                        onChange={(e) => {
                            const list = e.value.map((x) => {
                                return x.code
                            })
                            console.log(list);
                            setSelectedTemplates(e.value)
                        }} />
                </div>

                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Export code as Zip</span>
                    <MultiSelect 
                        optionLabel="name" display="chip"
                        value={""} 
                        options={[{"name": "C#"}]} 
                    />
                </div>

                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Export code to Github</span>
                    <MultiSelect 
                        optionLabel="name" display="chip"
                        value={""} 
                        options={[{"name": "C#"}]} 
                    />
                </div>
                
            </Dialog>
        </>
    )
}