
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { useContext } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DiagramContext from '../../../context/diagram-context';
import DiagramBuilderService from '../../../services/diagram/diagram-builder-service';
import DiagramFunctions from '../../../services/functions/diagram-functions';
import Utils from '../../../services/utils/utils';
import { Divider } from 'primereact/divider';
import UtilsContext from "../../../context/utils-context"

export default function DiagramLeftPanel(props) {

    
    // App Context
    const {atmosResources, setDiagramDialogsDisplay, setResourceData, currentDiagramInfo} = useContext(DiagramContext);
    const {toast} = useContext(UtilsContext);

    // State
    // Hooks
    // Variables
    const navigate = useNavigate()
    const resourcesTreeData = [
        {
            "key": "0",
            "data":{
                "name":"Functions",
                "show": "function"
            },
            "children":[]
        },
        {
            "key": "1",
            "data":{
                "name":"Databases",
                "show": "database"
            },
            "children":[]
        }
    ]
    // Functions
    const getResourceIndex = function(resourceType){
        const data = {
            "function": 0,
            "database": 1
        }
        return data[resourceType]
    }

    const transformResourcesToTreeData = function(atmosResources) {
        
        atmosResources.forEach(resource => {
            const index = getResourceIndex(resource.resourceType)
            resourcesTreeData[index]["children"].push({
                    
                "key": resource.id,
                "data":{
                    "name":resource.name,
                },
            })
                     
        });
        return resourcesTreeData
    }
    
    // Html
    const treeActionTemplate = (node, column) => {
        if(node.children == undefined){
            return <div>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning"
                    onClick={() => {
                        const resource = atmosResources.find(x => x.id == node.key);
                        setResourceData(resource)
                    }}>
                </Button>
            </div>;
        }else{
            return <div>
                <Button type="button" icon="pi pi-plus" className="p-button-success"
                    onClick={() => {
                        setResourceData(undefined);
                        setDiagramDialogsDisplay(Utils.showDialog(node.data.show))
                    }}>
                    
                </Button>
            </div>;
        }
        
    }
        

    return (

        <div className='diagram-left-panel'>

            {/* Title */}
            <div style={{"fontSize": "1.5em", "margin": "1em", "textAlign": "center"}}>{currentDiagramInfo.name}</div>
            <Divider></Divider>

            {/* Resources Tree */}
            <TreeTable style={{"margin": "0.5em"}} value={transformResourcesToTreeData(atmosResources)}>
                <Column header="Name" field="name" expander></Column>
                <Column header="Action" body={treeActionTemplate} style={{ textAlign: 'center', width: '10rem' }} ></Column>
            </TreeTable>

            <Button style={{"margin": "1em 3em 1em 3.5em", "width": "70%"}} label="Save" type="button" className="p-button-secondary"
                onClick={async () => {
                    const response = await DiagramFunctions.updateDiagram({...currentDiagramInfo, resources: atmosResources});
                    if(response.status == 200){
                        toast.current.show({severity:'success', summary: 'Diagram Saved', life: 2000});
                    }
                }}>
            </Button>


            <Button style={{"margin": "0 3em 0 3.5em", "width": "70%"}} label="Dashboard" type="button" className="p-button-help"
                onClick={() => {
                    navigate("/diagram-dashboard/"+currentDiagramInfo.id)
                }}>
            </Button>

        </div>
        
    );
}