import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { AiOutlineFunction } from "react-icons/ai"
import { Button } from 'primereact/button';

const handleStyle = { left: 10 };

function FunctionNode({ data }) {
    
    return (
        <>
            <Handle type="target" position={Position.Left} />
            <Handle type="source" position={Position.Right} />
            <Button disabled={true}>
                <div className='diagram-node'>
                    <div className='node-icon'>
                        <AiOutlineFunction></AiOutlineFunction>
                    </div>
                    <div className='node-name'>
                        {data.label}
                    </div>
                </div>
                
            </Button>
            
            
        </>
    );
}

export default FunctionNode;