
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { TreeTable } from 'primereact/treetable';
import { useContext } from 'react';
import { useState } from 'react';
import DiagramContext from '../../../context/diagram-context';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import Utils from '../../../services/utils/utils';
import DiagramBuilderService from '../../../services/diagram/diagram-builder-service';


export default function FunctionForm() {

    // App Context
    const {
        resourceData, setResourceData, setDiagramDialogsDisplay,
        atmosResources, setAtmosResources,
        setDiagramNodes, setDiagramEdges
    } = useContext(DiagramContext);
    // State
    // Hooks
    // Variables
    const functionSubTypes = [
        "default",
        "crud"
    ]
    // Functions

   

    // HTML

    return (
        <>
            <div>

                {/* Resource ID */}
                

                {/* Name */}
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Name</span>
                    <InputText value={resourceData?.name} onChange={(e) => {
                        setResourceData(
                            {...resourceData, name: e.target.value}
                        )}
                    } />
                </div>

                {/* Type */}
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Type</span>
                    <Dropdown value={resourceData?.subType} options={functionSubTypes} onChange={(e) => {
                        setResourceData(
                            {...resourceData, subType: e.value}
                        )
                    }} placeholder="Select a Function Sub Type"/>
                </div>
            </div>
        </>        
    );
}