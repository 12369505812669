import HttpService from "../http/http-service"
import log from 'loglevel';

const ProjectsFunctions = {

    async createProject(name, ownerId, token){
        return await HttpService.post(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/projects", {
            "name": name,
            "ownerId": ownerId,
            "githubAuthToken": token
        })
    },

    async getProjectsById(id){
        return await HttpService.get(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/projects/" + id)
    },

    async getProjectsByOwner(ownerId){
        return await HttpService.get(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/projects/owner/" + ownerId)
    },

    async deleteProjectById(projectId){
        return await HttpService.delete(process.env.REACT_APP_BASE_FUNCTIONS_URL + "/api/projects/" + projectId)
    }
}

export default ProjectsFunctions;